import React from 'react'
import { withRouter } from 'react-router-dom'
import Dialog from '../../components/dialog/dialog.component'
import Loading from '../../components/loading/loading.component'
import { connect } from 'react-redux'
import Filters from './filters.component'
import Pagination from '../../components/pagination/pagination.component'
import './users.styles.scss'
import Service from './users.service'

class Users extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadUsers()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadUsers())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadUsers()
    }

    render() {
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                <div className="users">
                    <div className="header">
                        <h4>Users</h4>
                        <div className="buttons">
                            <Filters filter={this.state.filter} onSubmit={this.onFilterSubmit} onChange={this.onFilterChange} />
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Firstname</th>
                                <th>Lastname</th>
                                <th>Phone</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Country</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.users.map((user, key) => <tr key={key}>
                                    <td>{user.username}</td>
                                    <td>{user.firstname}</td>
                                    <td>{user.lastname}</td>
                                    <td>{user.phone}</td>
                                    <td>{user.city}</td>
                                    <td>{user.state}</td>
                                    <td>{user.country}</td>
                                    <td>{new Date(user.createdAt).toString('dd MMM, yyyy')}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Users))