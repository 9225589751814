import axios from 'axios'
import Validation from '../../../data/validation/validation'
import { EmailValidationRule, NumberValidationRule, StringValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'
import { API_ENDPOINT } from '../../../config'

const getDefaultState = () => {
    return {
        saving: false,
        value: "",
        username: "",
        description: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(EmailValidationRule, instance.state.username, (error) => instance.state.errors.username = error, { allowNull: false })
    validation.addValidationRule(NumberValidationRule, instance.state.value, (error) => instance.state.errors.value = error, { min: { value: 1, error: "Invalid" }, max: { value: 1000000, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.description, (error) => instance.state.errors.description = error, { min: { value: 3, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/wallet/transactions/sell",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            username: instance.state.username,
            value: instance.state.value,
            currency: 1,
            type: 1,
            description: instance.state.description
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                flag: {
                    type: "success",
                    text: "Successful"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        isValid: () => isValid(instance),
        save: () => save(instance)
    }
}

export default Service