import React from 'react'
import { Switch, Route } from "react-router-dom"
import Users from './users.page'

const UserController = () => {
    return (
        <Switch>
            <Route path="/users/all" component={Users} />
        </Switch>
    )
}

export default UserController