import React from 'react'
import './form.styles.scss'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="value">
                    <div className="form-label">
                        <label>User</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="username" value={props.username} onChange={props.onChange} placeholder="username" />
                        <span className="error">{props.errors.username}</span>
                    </div>
                </div>
                <div className="row" id="value">
                    <div className="form-label">
                        <label>Eastcoins</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="value" value={props.value} onChange={props.onChange} />
                        <span className="error">{props.errors.value}</span>
                    </div>
                </div>
                <div className="row" id="description">
                    <div className="form-label">
                        <label>Description</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="description" value={props.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="submit" onClick={props.onSubmit}>Sell</button>
            </div>
        </form>
    )
}

export default Form