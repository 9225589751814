import React from 'react'
import { connect } from 'react-redux'
import './dashboard.styles.scss'
import { withRouter } from 'react-router-dom'
import Service from './dashboard.service'

class Dashboard extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {

    }

    render() {
        if (this.state.loading) {
            return (
                <div className="dashboard">
                    <div className="cards">
                        <p>loading...</p>
                    </div>
                </div>
            )
        }
        return (
            <div className="dashboard">
                
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Dashboard))