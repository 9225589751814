import iconDashboard from '../../../assets/icons/dashboard.svg'
import iconPayer from '../../../assets/icons/payer.svg'
import iconSettings from '../../../assets/icons/config.svg'

const DefaultStore = {
    showMenu: false,
    activeItem: 0,
    items: [
        {
            text: "Dashboard",
            icon: iconDashboard,
            subItems: []
        },
        {
            text: "Transactions",
            icon: iconPayer,
            subItems: [
                {
                    url: "/transactions/create",
                    text: "Sell Eastcoin"
                },
                {
                    url: "/transactions/all",
                    text: "All Transactions"
                }
            ]
        },
        {
            text: "Users",
            icon: iconSettings,
            subItems: [
                {
                    url: "/users/all",
                    text: "Users"
                }
            ]
        },
        {
            text: "Referrals",
            icon: iconDashboard,
            subItems: [
                {
                    url: "/referrals",
                    text: "All Referrals"
                }
            ]
        }
    ]
}

export default DefaultStore